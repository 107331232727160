import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-components/loadhandlers/Loadable';

const MyApps = Loadable(lazy(() => import('views/my-apps/index')));
const Deals = Loadable(lazy(() => import('views/Deals')));
const Profile = Loadable(lazy(() => import('views/profile/Profile')));

const NotificationSettings = Loadable(lazy(() => import('views/notification-settings/NotificationSettings')));
const Referral = Loadable(lazy(() => import('views/referral/Referral')));
const PointsPortal = Loadable(lazy(() => import('views/points/PointsPortal')));

const ItineraryAIForm = Loadable(lazy(() => import('views/ItineraryAIForm')));
const ItineraryAIFinalPlan = Loadable(lazy(() => import('views/ItineraryAIFinalPlan')));

const InfluencerDashboard = Loadable(lazy(() => import('views/influencer/Influencer')));

const UserRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'my-apps',
      element: <MyApps />
    },
    {
      path: 'flight-deal-scanner',
      element: <Deals />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'notification-settings',
      element: <NotificationSettings />
    },
    {
      path: 'itinerary-ai',
      element: <ItineraryAIForm />
    },
    {
      path: 'itinerary-ai-plan',
      element: <ItineraryAIFinalPlan />
    },
    {
      path: 'referral',
      element: <Referral />
    },
    {
      path: 'points',
      element: <PointsPortal />
    },
    {
      path: 'influencer/:childpath',
      element: <InfluencerDashboard />
    },
    {
      path: 'influencer/invite-a-user',
      element: <Referral />
    }
  ]
};

export default UserRoutes;
