import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography, Box, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';

import DrawerContext from 'contexts/DrawerContext';
import { handleNavigateToHome } from 'utils/navigate-helpers';

import {
  AccountCircle as AccountCircleIcon,
  CircleNotifications as CircleNotificationsIcon,
  AirplaneTicket as AirplaneTicketIcon,
  TravelExplore as TravelExploreIcon,
  Home as HomeIcon,
  PersonAddAlt1 as PersonAddAlt1Icon,
  Loyalty as LoyaltyIcon,
  Logout as LogoutIcon,
  Analytics as AnalyticsIcon,
  AutoGraph as AutoGraphIcon,
  Dashboard as DashboardIcon
} from '@mui/icons-material';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ drawerOpen }) => {
  const { leftDrawerOpened: opened } = useContext(DrawerContext);
  const userData = useSelector((state) => state.user?.data);

  const userType = userData?.userType || 'user';

  const appMenuOptions = [
    {
      id: 'apps',
      title: 'My Apps',
      type: 'item',
      url: '/my-apps',
      icon: DashboardIcon
    },
    {
      id: 'notification-settings',
      title: 'Notification Settings',
      type: 'item',
      url: '/notification-settings',
      icon: CircleNotificationsIcon
    },
    {
      id: 'flight-deal-scanner',
      title: 'Flight Deal Scanner',
      type: 'item',
      url: '/flight-deal-scanner',
      icon: AirplaneTicketIcon,
      breadcrumbs: false
    },
    {
      id: 'points-scanner',
      title: 'Points Deal Scanner',
      type: 'item',
      url: '/points',
      icon: LoyaltyIcon,
      breadcrumbs: false
    },
    {
      id: 'trip-planner-ai',
      title: 'Trip Planner AI',
      type: 'item',
      url: '/itinerary-ai',
      icon: TravelExploreIcon
    }
  ];

  const userMenuOptions = [
    {
      id: 'profile',
      title: 'My Profile',
      type: 'item',
      url: '/profile',
      icon: AccountCircleIcon
    },
    {
      id: 'referrals',
      title: 'Refer a Friend',
      type: 'item',
      url: '/referral',
      icon: PersonAddAlt1Icon
    },
    {
      id: 'logout',
      title: 'Log Out',
      type: 'item',
      url: '/',
      icon: LogoutIcon
    }
  ];

  const influencerMenuOptions = [
    {
      id: 'influencer',
      title: 'My Referrals',
      type: 'item',
      url: '/influencer/referrals',
      icon: AnalyticsIcon
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'item',
      url: '/influencer/analytics',
      icon: AutoGraphIcon
    },
    {
      id: 'invite-user',
      title: 'Add Referrals',
      type: 'item',
      url: '/influencer/invite-a-user',
      icon: PersonAddAlt1Icon
    }
  ];

  const appMenuItems = [
    {
      id: 'app-features',
      type: 'group',
      children: [...appMenuOptions]
    }
  ];

  const basicMenuItems = [
    {
      id: 'core-features',
      type: 'group',
      children: [...userMenuOptions]
    }
  ];

  const influencerMenuItems = [
    {
      id: 'influencer',
      type: 'group',
      children: [...influencerMenuOptions]
    }
  ];

  const menuItemsMappedByUserType = {
    user: [...appMenuItems, ...basicMenuItems],
    influencer: [...influencerMenuItems, ...appMenuItems, ...basicMenuItems]
  };

  const navItems = menuItemsMappedByUserType[userType].map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} opened={drawerOpen} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <Box mt={12}>
      <ListItemButton
        sx={{
          borderRadius: '12px',
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: '#eef2f6',
          py: 1.25,
          pl: `${1 * 24}px`
        }}
        onClick={handleNavigateToHome}
      >
        <ListItemIcon
          sx={{
            my: 'auto',
            minWidth: 36,
            color: 'inherit'
          }}
        >
          <HomeIcon color="inherit" />
        </ListItemIcon>
        <ListItemText
          primary={
            opened ? (
              <Typography
                sx={{ textWrap: 'nowrap' }}
                variant="h5"
                // variant={isNavItemSelected ? "h5" : "body1"}
                color="inherit"
              >
                Home
              </Typography>
            ) : (
              ''
            )
          }
        />
      </ListItemButton>
      <Divider sx={{ my: 2 }} />
      {navItems}
    </Box>
  );
};

MenuList.propTypes = {
  drawerOpen: PropTypes.bool
};

export default MenuList;
