// react-router-dom
import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import UserRoutes from './UserRoutes';
import OnboardingRoutes from './OnboardingRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // public routes
  const routes = [AuthenticationRoutes, OnboardingRoutes, UserRoutes];

  return useRoutes(routes);
}
