import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PortalProtector = ({ children }) => {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.user) || {};

  useEffect(() => {
    if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn, navigate]);

  return children;
};

export default PortalProtector;
